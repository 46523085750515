import React from 'react'
import { useNavigate } from 'react-router'
import type { NavigateOptions, NavigateFunction } from 'react-router'
import { useLinkUrlWithQueryString } from './link-url-with-query-string'

/**
 * Navigate to a react router URL, keeping the query string in the current URL
 */
export function useNavigateWithQueryString(): NavigateFunction {
    const navigate = useNavigate()
    const buildLinkUrlWithQueryString = useLinkUrlWithQueryString()

    return React.useCallback(
        (toOrDelta: any, options?: NavigateOptions) => {
            if (typeof toOrDelta === 'string') {
                navigate(buildLinkUrlWithQueryString(toOrDelta), options)
            } else navigate(toOrDelta, options)
        },
        [buildLinkUrlWithQueryString, navigate]
    )
}
