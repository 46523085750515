const featureFlags = {
    pdb: {
        lists: true,
        lotDetails: true,
        includeBce: false,
        insights: true,
    },
}

export default featureFlags
