import { useMemo } from 'react'
import { isNonProductionEnv } from '../utils'
import { isServer } from '~/utils'
import useSWR from 'swr'
import clientConfig from '~/config/client-config'

export interface UseRemixLoaderOptions {
    clientOnly?: boolean
    fetchOptions?: RequestInit

    // Allows us to control whether or not to fetch, for cases where we don't necessarily
    // want to fetch when a component first loads
    readyToFetch?: boolean
}

interface FetchJsonOptions extends UseRemixLoaderOptions {
    fetchOptions?: RequestInit
}

// Because we're using Cloudflare Access to protect our non-production environments, cookies
// need to be included in the request for requests to succeed across subdomains.
// If it's a new subdomain, don't forget to also update the CORS settings in CF Access to
// include that subdomain.
const getDefaultFetchOptions: () => RequestInit = () =>
    isNonProductionEnv(clientConfig) ? { credentials: 'include' } : {}

// exported for the test
export async function fetchJson(
    url: RequestInfo | URL,
    // Note: clientOnly defaults to true because syndicated components should be cacheable,
    // which means they shouldn't normally be making graphql requests during React SSR.
    {
        clientOnly = true,
        readyToFetch = true,
        fetchOptions = getDefaultFetchOptions(),
    }: FetchJsonOptions = {}
) {
    if (!readyToFetch || (clientOnly && isServer)) {
        return null
    }

    // could add additional error handling to capture status codes, etc
    const data = await fetch(url, fetchOptions)

    if (data.status !== 200) {
        const baseMessage = `Failed to send request to ${url}`
        let message
        try {
            message = baseMessage + ': ' + (await data.text())
        } catch {
            message = baseMessage
        }
        throw Error(message)
    }
    return data.json()
}

export function useRemixLoader<T = any>(
    routeUrl: string,
    options?: UseRemixLoaderOptions
) {
    const urlToFetch = useMemo(() => {
        if (isServer) {
            return ''
        }
        const url = routeUrl.startsWith('http')
            ? new URL(routeUrl)
            : new URL(clientConfig.URL_SCHEME_AND_AUTHORITY + routeUrl)

        url.searchParams.append('_data', 'routes' + url.pathname)
        return url.toString()
    }, [routeUrl])

    const { data, isLoading, isValidating, error, mutate } = useSWR(
        urlToFetch,
        (url: RequestInfo | URL) => fetchJson(url, options)
    )
    return {
        data: data as T,
        isLoading,
        isValidating,
        error,
        mutate,
    }
}
