import { useState } from 'react'
import { GlobalHeader } from './GlobalHeader'
import {
    CurrentUserResult,
    useGetCurrentUserLoader,
} from '~/hooks/api/get-current-user'
import {
    useScriptOnlyParams,
    useServerTemplateParamGetter,
} from '~/syndicated-components/hooks'
import {
    getChangePasswordUrl,
    isLoggedInNonAuthoritative,
    isLoggedIntoSSONonAuthoritative,
} from '~/utils/auth-shared-syndicated'
import { RootContext, RootContextType } from '~/root-context'
import config from '~/syndicated-components/isomorphic-config'

const isServer = typeof document === 'undefined'

export interface GlobalHeaderSyndicationContainerProps {
    syndicatedComponentId: string
    renderSubheaderDesktop?: () => React.ReactNode
    renderSubheaderMobile?: () => React.ReactNode
}

export default function GlobalHeaderSyndicationContainer(
    props: GlobalHeaderSyndicationContainerProps
) {
    const [loading, { authUrls, enableStickyScroll }] = useScriptOnlyParams(
        props.syndicatedComponentId
    )
    const [globalHeaderVisible, setGlobalHeaderVisible] = useState(true)

    // console.log('script-only props: ', { authUrls, enableStickyScroll });

    // the change-password URL doesn't need to be set by the legacy app because it's consistent
    // between all our apps (unlike login and register links, which vary depending on which
    // OIDC client it is)
    if (!loading && authUrls) {
        authUrls.changePassword = getChangePasswordUrl(window.location.href)
    }

    // isServer check is because we want pages to be cacheable, so we only check login status
    // on the client.
    // isLoggedInNonAuthoritative() check is for efficiency - we don't bother querying graphql
    // for user info if we know the user isn't logged in
    const readyToFetch = !isServer && isLoggedInNonAuthoritative()

    const { data: loaderData, error } = useGetCurrentUserLoader({
        readyToFetch: readyToFetch,
    })

    const notLoggedInToGatewayAndSkippedFetch = !isServer && !readyToFetch

    let currentUserResult: CurrentUserResult | undefined
    if (error) {
        console.error(error)
    } else if (loaderData) {
        // If we already fetched the response, simply use it
        if (loaderData.data) {
            currentUserResult = loaderData?.data
        } else {
            // HTTP 200 response but an error returned by the loader.
            // (We don't want the loader to break the whole page in the case of the remix app,
            // so /api/frontend/get-current-user.ts catches errors)
            console.error(loaderData.error!)
        }
    } else if (notLoggedInToGatewayAndSkippedFetch) {
        // We might have skipped fetching - we skip it if we alredy *know* (based on the
        // absence of a gateway login cookie) that the user isn't logged in to the gateway.
        // In this case, we fake the loaderData to avoid having to make an unnecessary
        // graphql request.
        currentUserResult = {
            __typename: 'CurrentUserResult',
            isLoggedIn: false,
            // Note: when running as a syndicated component, this `isReauthenticationRequired`
            // property is unused, but we include it here for consistency. We *do* check the SSO
            // login cookie as a fallback just in case the user is already logged into SSO but
            // not the gateway (which normally shouldn't happen), but that is done earlier, in
            // app/syndicated-components/delivery-packages/global-header/login-redirect.ts.
            isReauthenticationRequired: isLoggedIntoSSONonAuthoritative(),
            user: null,
        }
    }

    const rootContextObj = {
        authUrls,
        currentUserResult,
        setGlobalHeaderVisible,
        globalHeaderVisible,
    } as RootContextType

    const getServerTemplateParam = useServerTemplateParamGetter(
        props.syndicatedComponentId,
        props
    )

    const theme = getServerTemplateParam('theme')

    return (
        <RootContext.Provider value={rootContextObj}>
            <div className={theme}>
                <GlobalHeader
                    {...props}
                    theme={theme}
                    siteBaseUrl={config.URL_SCHEME_AND_AUTHORITY}
                    enableStickyScroll={enableStickyScroll}
                />
            </div>
        </RootContext.Provider>
    )
}
