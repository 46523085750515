import clientConfig from '~/config/client-config'
import serverConfig from '~/config/config.server'
import { isServer } from '~/utils'

// **** IMPORTANT NOTE ****
// This file can *only* safely be used from syndicated component delivery packages that
// aren't used in our Remix app, thanks to our custom ESBuild setup for syndicated components
// (specifically our custom `emptyModules` ESBuild plugin).
//
// Otherwise, you'll need to import `~/config/config.client` or `~/config/config.server`
// directly.

export default isServer ? serverConfig : clientConfig
