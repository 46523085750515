import React from 'react'
import { useGlobalSearchLoader } from '~/hooks/api/global-search'
import { GlobalHeaderSearchView } from './views/GlobalHeaderSearchView'
import { SearchHint, SearchType } from '~/services/graphql/generated'
import type { GlobalHeaderSearchContainerProps } from './types'

function useGlobalHeaderSearchData() {
    const [globalSearchResult, setGlobalSearchResult] = React.useState<
        Array<SearchHint>
    >([])
    const [queryString, setQueryString] = React.useState<string>('')
    const [searchType, setSearchType] = React.useState<SearchType>(
        SearchType.Global
    )

    const readyToFetch = queryString !== ''

    const { data: loaderData } = useGlobalSearchLoader(
        queryString,
        searchType,
        readyToFetch
    )

    React.useEffect(() => {
        if (loaderData && queryString) {
            setGlobalSearchResult(loaderData.results)
        }
    }, [loaderData, queryString])

    const fetchHints = React.useCallback(
        (searchTerm: string, searchType: SearchType) => {
            setQueryString(searchTerm)
            setSearchType(searchType)
        },
        []
    )

    return {
        fetchHints,
        globalSearchResult,
    }
}

export function GlobalHeaderSearchSyndicationContainer({
    siteBaseUrl,
    isSearchOpen,
    onToggleSearchOpen,
    desktopHeight,
    theme,
    getServerTemplateParam,
}: GlobalHeaderSearchContainerProps) {
    const { fetchHints, globalSearchResult } = useGlobalHeaderSearchData()

    return (
        <GlobalHeaderSearchView
            siteBaseUrl={siteBaseUrl}
            searchResult={globalSearchResult}
            fetchHints={fetchHints}
            isSearchOpen={isSearchOpen}
            onToggleSearchOpen={onToggleSearchOpen}
            desktopHeight={desktopHeight}
            theme={theme}
            getServerTemplateParam={getServerTemplateParam}
        />
    )
}
