import React from 'react'
import { isNonProductionEnv } from '../utils'
import useSWRMutation from 'swr/mutation'
import clientConfig from '~/config/client-config'
import { isServer } from '~/utils'

// see remix-loader.ts for explanation of these default options
const getDefaultFetchOptions: () => RequestInit = () =>
    isNonProductionEnv(clientConfig) ? { credentials: 'include' } : {}

export function useSendRequest(fetchOptions: RequestInit) {
    const sendRequest = React.useCallback(
        async (url: RequestInfo | URL, { arg }: { arg: any }) => {
            const options = {
                ...fetchOptions,
                method: 'POST',
                body: JSON.stringify(arg),
            }

            const data = await fetch(url, options)

            if (data.status !== 200) {
                const baseMessage = `Failed to send request to ${url}`
                let message
                try {
                    message = baseMessage + ': ' + (await data.text())
                } catch {
                    message = baseMessage
                }
                throw Error(message)
            }

            return data.json()
        },
        [fetchOptions]
    )

    return sendRequest
}

export function useRemixAction<Data, TriggerParams>(routeUrl: string) {
    const urlToFetch = React.useMemo(() => {
        if (isServer) {
            return ''
        }
        const url = new URL(clientConfig.URL_SCHEME_AND_AUTHORITY + routeUrl)
        url.searchParams.append('_data', 'routes' + routeUrl)
        return url.toString()
    }, [routeUrl])

    const sendRequest = useSendRequest(isServer ? {} : getDefaultFetchOptions())
    const { data, error, isMutating, reset, trigger } = useSWRMutation<
        Data,
        any,
        string,
        TriggerParams
    >(urlToFetch, sendRequest)

    return {
        data: data as Data,
        error,
        isMutating,
        reset,
        trigger,
    }
}
