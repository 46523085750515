import React from 'react'
import {
    Input,
    InputProps,
    Select,
    SelectOption,
    SelectProps,
    Textarea,
    TextareaProps,
} from '@artnetworldwide/ui-library'
import {
    Form as RemixFrameworkForm,
    useActionData,
    useSubmit,
    useNavigation,
} from 'react-router'
import { FormProps, createForm, useField } from 'remix-forms'

export * from './ReactHookFormWrapper'

type InputFieldProps = InputProps | TextareaProps

// note: Record<string, any> is a workaround for compatibility with remix-forms's types
export const RemixInputField = React.forwardRef<
    any,
    InputFieldProps & Record<string, any>
>(({ defaultValue, ...rest }, ref) => {
    const { label, multiline, errors } = useField()
    const Component = (
        multiline ? Textarea : Input
    ) as React.FunctionComponent<InputFieldProps>

    return (
        <Component
            ref={ref}
            {...rest}
            label={label ?? ''}
            hasError={Boolean(errors)}
        />
    )
})
RemixInputField.displayName = 'RemixInputField'

// Note: this component doesn't update in response to setValue(), and the onChange prop doesn't work.
// It is only suitable for simple usage as component that only needs to change in response to the
// user selecting options.
export const RemixSelectField = React.forwardRef<
    any,
    JSX.IntrinsicElements['select'] & SelectProps
>(({ defaultValue, ...rest }, ref) => {
    const { label, options, errors, value } = useField()
    const selectOptions: SelectOption[] = (options ?? []).map(
        ({ name, value }) => ({
            name,
            // We are supporting arrays as values for compatibility with native JSX <option>s,
            // since that's what remix-forms expects (and its typescript types are based on it).
            // In practice, this probably won't be used.
            value: Array.isArray(value) ? value.join(',') : value.toString(),
        })
    )

    return (
        <Select
            {...rest}
            label={label ?? ''}
            labelPlacement="inline"
            value={value ?? defaultValue}
            options={selectOptions}
            hasError={Boolean(errors)}
        />
    )
}) as NonNullable<FormProps<any>['selectComponent']> & { displayName: string }
RemixSelectField.displayName = 'RemixSelectField'

// IMPORTANT:
// Do not use this component when the Input component's `labelPlacement` is set to anything
// other than 'inline', otherwise you'll end up with two <label>s in the HTML!
//
// This component is useful because our library form components use `placeholder` to show the
// label (when labelPlacement=inline, which is the default), so we want to hide the <label>
// added by the remix-forms library: we hide it, but leave it in the DOM for better accessibility.
export function HiddenLabel(props: JSX.IntrinsicElements['label']) {
    return <label {...props} hidden />
}

export function FormFieldError({ children }: { children?: React.ReactNode }) {
    return <div className="text-state-error text-left">{children}</div>
}

export const RemixForm = createForm({
    component: RemixFrameworkForm,
    useActionData,
    useSubmit,
    useNavigation,
})
