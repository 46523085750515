/**
 * Utility functions shared by both the remix app and syndicated components.
 */

import { memoizeFirst } from '@artnetworldwide/ui-library/utils'
import originalClientConfig from '~/config/client-config'
import { getRootDomain } from './urls'

const clientConfig = originalClientConfig ?? {}

export function getAuthUrl(
    type: 'login' | 'register' | 'logout',
    apiGatewayBaseUrl: string,
    returnUrl: string,

    // backoutUrl is the URL where the user will be redirected if they click the
    // "X" button on the upper-right corner of the login page.
    //
    // IMPORTANT: do not set this to a URL that requires login to access it, or else
    // it will cause an infinite redirect loop!
    backoutUrl: string
) {
    const urlParams = new URLSearchParams({ returnUrl })
    if (type === 'login' || type === 'register') {
        urlParams.append('backoutUrl', backoutUrl)
    }
    return `${apiGatewayBaseUrl}/${type}?${urlParams}`
}

// Get base URL for SSO, e.g. `https://login.artnet-dev.com`
// We do this by convention ('login' subdomain) to avoid the need for yet another
// environment variable.
export const getSSOBaseUrl = memoizeFirst((appBaseUrl: string) => {
    const rootDomain = getRootDomain(new URL(appBaseUrl))
    return `https://login.${rootDomain}/`
})

export function getChangePasswordUrl(currentUrl: string) {
    return (
        getSSOBaseUrl(currentUrl) +
        `ChangePassword?ReturnUrl=${encodeURIComponent(currentUrl)}`
    )
}

const ssoCookieRegex = new RegExp(
    `\\b${clientConfig.CLIENT_ACCESSIBLE_SSO_COOKIE_NAME}\\b`
)

const gatewayCookieRegex = new RegExp(
    `\\b${clientConfig.CLIENT_ACCESSIBLE_GATEWAY_AUTH_COOKIE_NAME}\\b`
)

// Non-authoritative check to see if the user is logged in to the API gateway.
// This isn't a secure method of guaranteeing that the user is really logged in, and should
// only be used for determining whether to show a logged-in state in the UI (e.g. "Welcome, Fred")
export function isLoggedInNonAuthoritative() {
    return gatewayCookieRegex.test(document.cookie)
}

// Non-authoritative check to see if the user is logged in to SSO.
// This can be true even if the user is NOT logged into the gateway (in that case the unified header
// automatically redirects them to establish their gateway session).
export function isLoggedIntoSSONonAuthoritative() {
    return ssoCookieRegex.test(document.cookie)
}
