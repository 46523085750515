import React from 'react'
import { omit } from '@artnetworldwide/ui-library/utils'
import { useFetcher, useOutletContext } from 'react-router'
import type { PdbLayoutContext } from '~/routes/price-database2/_context'
import type {
    AggregationResult,
    EntityAggregationResults,
} from '~/services/graphql/generated'
import type { FilterAttributes } from '~/types/filters'
import { getExecutableFilters } from '~/services/transformations/input-transformations/art-listing-filters'

// for use with the AdvancedFiltersAutoSuggest component
export function useFilterSuggestions(
    selectedFilterName: string,

    // Filter attributes that should be included in the aggregation query.
    // These should not include the `includeUnknown` attribute, which is not intended for use
    // with the `getArtListingAggregations` query for entity filters.
    aggregationAttributes?: Omit<FilterAttributes, 'includeUnknown'>
): {
    aggregationResults: AggregationResult[]
    fetchAggregations: (searchTerms: string) => void
    countUnknown?: number
} {
    const fetcher = useFetcher<{
        aggregations: EntityAggregationResults
    }>()
    // we're using a `hints` state variable as an optimization to avoid calling
    // fetcher.submit() in the case where searchTerms is empty
    const [aggregationResults, setAggregationResults] = React.useState<
        AggregationResult[]
    >([])

    const countUnknownRef = React.useRef<number>()

    // initial suggestions should not be shown for these two filters
    // (see FE-701)
    const shouldShowInitialSuggestions =
        selectedFilterName !== 'lotTitle' && selectedFilterName !== 'sale'

    const ctx: PdbLayoutContext = useOutletContext()

    React.useEffect(() => {
        if (!(fetcher.state === 'idle' && fetcher.data !== null)) {
            return
        }

        // Only set countUnknownRef if it hasn't been set yet - i.e. on the first query responses.
        // This fixes FE-735
        if (countUnknownRef.current === undefined) {
            countUnknownRef.current = fetcher.data?.aggregations.countUnknown
        }

        if (fetcher.data) {
            setAggregationResults(
                fetcher.data.aggregations.results as AggregationResult[]
            )
        }
    }, [fetcher])

    // TODO
    // check if this would be more efficient if wrapped in useCallback()
    // (it might not be, so leaving it as-is for now)
    function fetchAggregations(searchTerms?: string) {
        // initial suggestions should not be shown for some filters (see FE-701)
        if (!shouldShowInitialSuggestions && searchTerms === '') {
            setAggregationResults([])
            return
        }

        const currentFilter = ctx.currentFilters[selectedFilterName]
        const filters = currentFilter?.values
            ? // remove selectedFilterName object so that all the filters will render during filter editing
              omit(ctx.currentFilters, selectedFilterName)
            : { ...ctx.currentFilters }

        // add the search term that the user entered in the autosuggest
        if (searchTerms) {
            filters[selectedFilterName] = {
                values: [{ searchTerms }],
                attributes: aggregationAttributes,
            }
        } else {
            filters[selectedFilterName] = {
                values: [{ searchTerms: '' }],
                attributes: aggregationAttributes,
            }
            setAggregationResults([])
        }

        const filtersToSubmit = getExecutableFilters(filters)

        fetcher.submit({
            filters: JSON.stringify(filtersToSubmit),
        })
    }

    return {
        aggregationResults,
        fetchAggregations,
        countUnknown: countUnknownRef.current,
    }
}
