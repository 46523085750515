import { LenderFormContainerProps, LenderInquiryResult } from './shared'
import { useCallback, useEffect, useState } from 'react'
import { LenderFormModal } from './LenderFormModal'
import { LenderFormValues } from './LenderFormForm'
import { useLenderFormAction } from '~/hooks/api/lender-form'
import { getSystemErrorMessage } from '~/utils/error-messages'

function useLenderFormSyndicationContainer(readyToFetch: boolean) {
    const [lenderInquiryResult, setLenderInquiryResult] =
        useState<LenderInquiryResult>({} as LenderInquiryResult)

    const {
        trigger,
        data: actionData,
        error: actionError,
    } = useLenderFormAction()

    useEffect(() => {
        if (actionError) {
            setLenderInquiryResult({
                ok: false,
                message: '',
                errors: [getSystemErrorMessage()],
            })
        } else if (actionData) {
            setLenderInquiryResult(actionData)
        }
    }, [actionError, actionData])

    const submitInquiry = useCallback(
        (lenderFormValues: LenderFormValues) => {
            trigger(lenderFormValues)
        },
        [trigger]
    )

    function handleFormSubmit(LenderFormValues: LenderFormValues) {
        submitInquiry(LenderFormValues)
    }

    return {
        lenderInquiryResult,
        handleFormSubmit,
    }
}

export function LenderFormSyndicationContainer({
    show,
    onClose,
}: LenderFormContainerProps) {
    const { handleFormSubmit, lenderInquiryResult } =
        useLenderFormSyndicationContainer(show)

    return (
        <>
            <LenderFormModal
                show={show}
                onClose={() => onClose(false)}
                onLenderInquiry={handleFormSubmit}
                lenderInquiryResult={lenderInquiryResult}
            />
        </>
    )
}
