import React from 'react'
import { useIsSyndicatedComponent } from '~/syndicated-components/hooks'
import clientConfig from '~/config/client-config'

interface DialogWrapperProps {
    className?: string
    wrapperClassName?: string
    children: React.ReactNode
    ref: React.RefObject<HTMLDivElement>
}

export function DialogWrapper({
    className,
    wrapperClassName,
    children,
    ref,
}: DialogWrapperProps) {
    const isSyndicatedComponent = useIsSyndicatedComponent()
    const Wrapper = isSyndicatedComponent ? 'div' : React.Fragment
    // prevent react warning about React.Fragment by using wrapperProps instead of passing className directly
    const wrapperProps = isSyndicatedComponent
        ? // this Dialog is only rendered on the client, so it's fine to just rely on clientConfig here
          { className: clientConfig.SYNDICATED_COMPONENT_CONTAINER_CLASS }
        : {}

    return (
        <Wrapper className={wrapperClassName} {...wrapperProps}>
            <div ref={ref} className={className}>
                {children}
            </div>
        </Wrapper>
    )
}
DialogWrapper.displayName = 'DialogWrapper'
