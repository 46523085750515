import { useIsSyndicatedComponent } from '~/syndicated-components/hooks'
import { clientOnly$, serverOnly$ } from 'vite-env-only/macros'
import { isServer } from '~/utils'
import serverConfig from '~/config/config.server'
import clientConfig from '~/config/client-config'
import type { BrowserSafeConfig, Config } from '~/config/config.server'

/**
 * Essential styles for the global header. These can optionally be added to the <head> section of the
 * HTML in order to defer loading the full stylesheet until later, in order to optimize performance.
 * @see https://web.dev/articles/defer-non-critical-css
 */
export function InlineStyles() {
    const isSyndicatedComponent = useIsSyndicatedComponent()

    let config: Config | BrowserSafeConfig
    if (isSyndicatedComponent) {
        // syndicated components build, not vite
        // (unless we upgrade it to use vite in the future)
        config = isServer ? (process.env as unknown as Config) : clientConfig
    } else {
        config = isServer
            ? (serverOnly$(serverConfig) as Config)
            : (clientOnly$(clientConfig) as BrowserSafeConfig)
    }

    const prefix = isSyndicatedComponent
        ? `.${config.SYNDICATED_COMPONENT_CONTAINER_CLASS} `
        : ''

    return (
        <style
            dangerouslySetInnerHTML={{
                __html: `
${prefix}.hidden {
    display: none
}
${prefix}.absolute{
    position: absolute
}
${prefix}.relative{
    position: relative
}
${prefix}.top-full{
    top: 100%
}
${prefix}.flex{
    display: flex
}
${prefix}.justify-between{
    justify-content: space-between
}
${prefix}.items-center{
    align-items: center
}
${prefix}.border-b-[1px]{
    border-bottom: 1px solid #e6e6e6ff
}
${prefix}.mt-2{
    margin-top: 8px
}
${prefix}.px-5{
    padding-left: 20px;
    padding-right: 20px
}
${prefix}.h-6{
    height: 24px;
    vertical-align: middle
}
${prefix}.h-5{
    height: 20px;
}
${prefix}.h-[56px]{
    height: 56px
}
${prefix}.h-[123px]{
    height: 123px
}
${prefix}.js_GlobalHeaderDesktop .pt-8{
    padding: 32px 32px 0 32px;
    margin: 0 auto
}
${prefix}.js_GlobalHeaderDesktop .pb-6{
    padding-bottom: 24px;
    justify-content: center;
    align-items: center
}
${prefix}.js_GlobalHeaderDesktop .leading-4{
    right: 1px
}
${prefix}.js_GlobalHeaderDesktop .flex div{
    align-items: baseline
}
${prefix}.js_GlobalHeaderDesktop .flex div:first-child{
    flex-grow: 1
}
${prefix}.js_GlobalHeaderDesktop .flex div nav > button{
    opacity: 0
}
${prefix}.js_GlobalHeaderDesktop .flex div:first-child nav div a{
    font-size: 16px;
    line-height: 24px;
    padding-right: 28px
}
${prefix}.js_GlobalHeaderDesktop > div:nth-child(2) div button{
    background-color: transparent;
    background-image: none;
    border: none
}
${prefix}.js_GlobalHeaderDesktop .flex div:first-child nav div button{
    font-size: 16px;
    line-height: 24px;
    padding: 0 28px 0 0;
    background-color: transparent;
    background-image: none;
    margin: 0;
    border: none
}
${prefix}.js_GlobalHeaderDesktop  .flex div:last-child{
    flex-grow: 0
}
${prefix}.js_GlobalHeaderDesktop .flex .flex:nth-of-type(2) nav div:nth-child(2){
    padding-right: 16px;
    font-weight: 700
}
${prefix}.js_GlobalHeaderDesktop .flex .flex:nth-of-type(2) nav div:nth-child(3){
    padding-right: 40px
}
${prefix}.js_GlobalHeaderDesktop .flex .flex:nth-of-type(2) nav div:nth-child(4){
    color: #737373ff
}
${prefix}.js_GlobalHeaderDesktop .flex .flex:nth-of-type(2) nav div:nth-child(4) button{
    padding: 0
}
@media (min-width: 1024px){
    ${prefix}.lg\\:hidden {
        display: none
    }
    ${prefix}.lg\\:block {
        display: block
    }
    ${prefix}.js_GlobalHeaderDesktop > div:nth-child(2) {
        display: none
    }
}
`.replace(/\n|(\s{2,})/g, ' '), // strip extraneous whitespace
            }}
        />
    )
}
