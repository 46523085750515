import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
    Accordion,
    Input,
    Select,
    Textarea,
    Checkbox,
    Button,
    SelectOption,
} from '@artnetworldwide/ui-library'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import type { SubmitHandler } from 'react-hook-form'
import { useLenderFormLoader } from '~/hooks/api/lender-form'
import { Country } from '~/services/graphql/generated'
import { FormFieldError } from '../forms'
import { Loader } from '~/components/Loader'
import { getSystemErrorMessage } from '~/utils/error-messages'

const loanAmounts = [
    {
        name: 'Select an amount',
        value: '',
    },
    {
        name: '25K - 100K USD',
        value: '25K-100K',
    },
    {
        name: '100K - 500K USD',
        value: '100K-500K',
    },
    {
        name: '500K - 1M USD',
        value: '500K-1M',
    },
    {
        name: '1M - 10M USD',
        value: '1M-10M',
    },
    {
        name: '10M+ USD',
        value: '10+M',
    },
]

const noLettersRegex = /^[^a-zA-Z]+$/

interface LenderFormFormProps {
    countries?: Map<string, string>
    errors: string[]
    onFormSubmit: (data: LenderFormValues) => void
}

export type LenderFormValues = {
    customerName: any
    customerPhone: any
    customerEmail: any
    customerPhoneExt: any
    customerCountryId: any
    messageBody: any
    artworkCountryId: any
    requestedLoanAmount: any
    multipleArtworks: boolean
}

export function LenderFormForm({
    onFormSubmit,
    errors,
}: Readonly<LenderFormFormProps>) {
    const serverErrorsRef = React.useRef<HTMLUListElement>(null)

    const { isLoading, data, error } = useLenderFormLoader()
    const combinedErrors = useMemo(() => {
        return error ? [...errors, getSystemErrorMessage()] : errors
    }, [error, errors])

    const countriesMap: Map<string, string> = useMemo(() => {
        return new Map(
            data?.countries?.map((country: Country) => [
                country.id,
                country.name,
            ])
        )
    }, [data?.countries])

    const countrySelectOptions: SelectOption[] = useMemo(() => {
        if (!countriesMap) return []
        return [{ name: 'Select country', value: '' }].concat(
            Array.from(countriesMap.entries(), ([key, value]) => ({
                name: value,
                value: key,
            }))
        )
    }, [countriesMap])

    React.useEffect(() => {
        if (combinedErrors.length) {
            showServerErrors()
        }
    }, [combinedErrors])

    const lenderFormSchema = z.object({
        customerName: z.string().min(1, { message: 'Name is required.' }),
        customerPhone: z
            .string()
            .regex(noLettersRegex, { message: 'Invalid phone number' })
            .min(1, { message: 'Please enter your phone number.' }),
        customerPhoneExt: z
            .string()
            .regex(noLettersRegex, { message: 'Invalid ext.' })
            .optional()
            .or(z.literal('')),
        customerEmail: z
            .string()
            .min(1, { message: 'Email is required.' })
            .email({ message: 'Invalid email address.' }),
        customerCountryId: z.string().refine((val) => val !== '', {
            message: 'Country is required.',
        }),
        requestedLoanAmount: z
            .string()
            .refine((val) => val !== 'Select an amount', {
                message: 'Value of desired loan is required.',
            }),
        artworkCountryId: z.string().refine((val) => val !== '', {
            message: 'Location of artwork (country) is required.',
        }),
        messageBody: z
            .string()
            .min(1, { message: 'Please enter at least one artist name.' }),
        multipleArtworks: z.boolean(),
    })

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
        control,
    } = useForm<LenderFormValues>({
        defaultValues: {
            customerName: '',
            customerPhone: '',
            customerEmail: '',
            customerPhoneExt: '',
            messageBody: '',
            customerCountryId: '',
            artworkCountryId: '',
            requestedLoanAmount: '',
        },
        resolver: zodResolver(lenderFormSchema),
    })

    const onSubmit: SubmitHandler<LenderFormValues> = async (input) => {
        onFormSubmit(input)
    }

    function showServerErrors() {
        serverErrorsRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    function renderErrorMessages(errorMessage: any) {
        return <FormFieldError>{errorMessage}</FormFieldError>
    }

    if (isLoading) {
        return <Loader loading={true} />
    }

    return (
        <main className="flex w-[352px] flex-col items-start justify-start bg-white">
            <ul ref={serverErrorsRef}>
                {combinedErrors?.map((error) => (
                    <li key={error} className="text-state-error text-left">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: error ?? 'Unknown system error',
                            }}
                        />
                    </li>
                ))}
            </ul>
            {countriesMap && (
                <div className="flex flex-col items-start justify-start gap-10">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col items-start justify-start gap-6">
                            <Accordion>
                                <Accordion.Item>
                                    <Accordion.Trigger>
                                        Learn more about financing
                                    </Accordion.Trigger>
                                    <Accordion.Content>
                                        <ul className="flex list-outside list-disc flex-col justify-between space-y-3 pl-4 text-left text-xs">
                                            <li>
                                                Fill in the inquiry form to
                                                receive information on how much
                                                you could borrow against your
                                                artwork(s) from our lending
                                                partners
                                            </li>
                                            <li>
                                                Tailor-made loans secured
                                                against fine art and
                                                collectibles
                                            </li>
                                            <li>
                                                Loans from $25,000 to over $10m
                                            </li>
                                            <li>Competitive interest rates</li>
                                            <li>50% estimated loan to value</li>
                                            <li>
                                                Fast and Confidential Collateral
                                                Appraisal and Loan Execution
                                                Process (Funding within 3-10
                                                business days of Appraisal)
                                            </li>
                                            <li>
                                                No Borrower Net Worth or Credit
                                                Requirements
                                            </li>
                                            <li>
                                                Loans provided to borrowers
                                                globally, including USA, Europe,
                                                and Asia
                                            </li>
                                            <li>
                                                To learn more, please contact{' '}
                                                <a href="mailto:lending@artnet.com">
                                                    lending@artnet.com
                                                </a>
                                            </li>
                                        </ul>
                                    </Accordion.Content>
                                </Accordion.Item>
                            </Accordion>
                            <div className="relative flex flex-col items-start justify-start gap-4 pb-4">
                                <div className="relative flex flex-col items-start justify-start gap-2">
                                    <div className="flex flex-col items-start justify-start border-b border-solid border-neutral-200 pb-6">
                                        <div className="relative flex flex-col items-start justify-center gap-1 self-stretch">
                                            <div className="w-full">
                                                <Input
                                                    className="js_Name"
                                                    containerClass="mt-1"
                                                    hasError={Boolean(
                                                        formErrors?.customerName
                                                            ?.message ?? false
                                                    )}
                                                    label="Name"
                                                    labelPlacement="above"
                                                    {...register(
                                                        'customerName'
                                                    )}
                                                />
                                                {renderErrorMessages(
                                                    formErrors?.customerName
                                                        ?.message
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex w-full flex-row pt-4">
                                            <div className="basis-3/4">
                                                <Input
                                                    className="js_PhoneNumber"
                                                    containerClass="mt-1"
                                                    hasError={Boolean(
                                                        formErrors
                                                            ?.customerPhone
                                                            ?.message ?? false
                                                    )}
                                                    label="Phone Number"
                                                    labelPlacement="above"
                                                    {...register(
                                                        'customerPhone'
                                                    )}
                                                />
                                                {renderErrorMessages(
                                                    formErrors?.customerPhone
                                                        ?.message
                                                )}
                                            </div>
                                            <div className="basis-1/4 pl-4 pt-6">
                                                <Input
                                                    className="js_Extension"
                                                    containerClass="mt-1"
                                                    hasError={Boolean(
                                                        formErrors
                                                            ?.customerPhoneExt
                                                            ?.message ?? false
                                                    )}
                                                    label=""
                                                    placeholder="ext."
                                                    {...register(
                                                        'customerPhoneExt'
                                                    )}
                                                />
                                                {renderErrorMessages(
                                                    formErrors?.customerPhoneExt
                                                        ?.message
                                                )}
                                            </div>
                                        </div>
                                        <div className="w-full pt-4">
                                            <Input
                                                className="js_Email"
                                                containerClass="mt-1"
                                                hasError={Boolean(
                                                    formErrors?.customerEmail
                                                        ?.message ?? false
                                                )}
                                                label="Email"
                                                labelPlacement="above"
                                                {...register('customerEmail')}
                                            />
                                            {renderErrorMessages(
                                                formErrors?.customerEmail
                                                    ?.message
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <Controller
                                            name="customerCountryId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    name="customerCountryId"
                                                    className="js_CustomerCountry"
                                                    hasError={
                                                        Boolean(
                                                            formErrors
                                                                ?.customerCountryId
                                                                ?.message
                                                        ) ?? false
                                                    }
                                                    label="Your location"
                                                    options={
                                                        countrySelectOptions
                                                    }
                                                    labelPlacement="above"
                                                    onChange={(e) => {
                                                        field.onChange({
                                                            target: e,
                                                        })
                                                    }}
                                                />
                                            )}
                                        />

                                        {renderErrorMessages(
                                            formErrors?.customerCountryId
                                                ?.message
                                        )}
                                    </div>
                                    <div className="flex w-full flex-col py-4">
                                        <Controller
                                            name="requestedLoanAmount"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    className="js_SelectLoanAmount"
                                                    hasError={Boolean(
                                                        formErrors
                                                            ?.requestedLoanAmount
                                                            ?.message ?? false
                                                    )}
                                                    label="Value of desired loan"
                                                    options={loanAmounts}
                                                    name="requestedLoanAmount"
                                                    labelPlacement="above"
                                                    onChange={(e) => {
                                                        field.onChange({
                                                            target: e,
                                                        })
                                                    }}
                                                />
                                            )}
                                        />
                                        {renderErrorMessages(
                                            formErrors?.requestedLoanAmount
                                                ?.message
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <Controller
                                            name="artworkCountryId"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    className="js_SelectLoanAmount"
                                                    hasError={Boolean(
                                                        formErrors
                                                            ?.artworkCountryId
                                                            ?.message ?? false
                                                    )}
                                                    label="Value of desired loan"
                                                    options={
                                                        countrySelectOptions
                                                    }
                                                    name="artworkCountryId"
                                                    labelPlacement="above"
                                                    onChange={(e) => {
                                                        field.onChange({
                                                            target: e,
                                                        })
                                                    }}
                                                />
                                            )}
                                        />
                                        {renderErrorMessages(
                                            formErrors?.artworkCountryId
                                                ?.message
                                        )}
                                    </div>
                                    <div className="w-full pt-4 text-left">
                                        <Textarea
                                            rows={8}
                                            className="js_messageBody"
                                            containerClass="mt-1"
                                            maxLength={2000}
                                            hasError={Boolean(
                                                formErrors?.messageBody
                                                    ?.message ?? false
                                            )}
                                            hint="2,000 character limit"
                                            label="Artist name(s) / notes"
                                            labelPlacement="above"
                                            placeholder="Enter one or more artist names in your collection"
                                            {...register('messageBody')}
                                        />
                                        {renderErrorMessages(
                                            formErrors?.messageBody?.message
                                        )}
                                    </div>
                                </div>
                                <div className="pt-[26px]">
                                    <Checkbox
                                        className="js_FinanceMultiple"
                                        error={
                                            formErrors?.multipleArtworks
                                                ?.message ?? ''
                                        }
                                        label="Finance multiple pieces?"
                                        {...register('multipleArtworks')}
                                    />
                                </div>
                            </div>
                            <Button
                                type="submit"
                                className="js_SendInquiry w-full !bg-black uppercase"
                            >
                                Send Inquiry
                            </Button>
                        </div>
                    </form>
                </div>
            )}
        </main>
    )
}
