import clientConfig from '~/config/client-config'
import {
    getAuthUrl,
    isLoggedInNonAuthoritative,
    isLoggedIntoSSONonAuthoritative,
} from '~/utils/auth-shared-syndicated'

export function checkLoginAndRedirectIfNeeded() {
    if (!isLoggedInNonAuthoritative() && isLoggedIntoSSONonAuthoritative()) {
        const currentUrl = location.href
        window.location.href = getAuthUrl(
            'login',
            clientConfig.API_GATEWAY_BASE_URL,
            currentUrl,
            currentUrl
        )
    }
}
