// import type { LayoutQueryResult } from '../../types/layout'
import type { AuthUrls } from '~/utils/auth'
import keyBy from 'lodash.keyby'
import { NavLinkType } from '~/components/headers/global/types'
import { getNewsHost } from './utils'

type HeaderData = ReturnType<typeof getRawHeaderData>

export function getHeaderData(
    siteBaseUrl: string,
    authUrls?: AuthUrls,
    isLoggedIn?: boolean,
    userDisplayName?: string
): HeaderData {
    return makeLinksAbsolute(
        getRawHeaderData(
            getNewsHost(siteBaseUrl),
            authUrls,
            isLoggedIn,
            userDisplayName
        ),
        siteBaseUrl
    ) as any as HeaderData
}

function getRawHeaderData(
    newsHost: string,
    authUrls?: AuthUrls,
    isLoggedIn?: boolean,
    userDisplayName?: string
) {
    const navLinks: NavLinkType[] = [
        // {
        //     id: 'artworks',
        //     label: 'Artworks',
        //     url: '/artworks',
        //     uiSpec: {
        //         useServerSideRouting: true,
        //         //desktop: 'ml-8',
        //     },
        //     group: 'a',
        // },
        {
            id: 'artists',
            label: 'Artists',
            url: '/artists',
            uiSpec: {
                useServerSideRouting: true,
            },
            group: 'a',
        },
        {
            id: 'auctions',
            label: 'Auctions',
            links: [
                {
                    id: 'artnet-auctions',
                    label: 'Artnet Auctions',
                    url: '/auctions/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'global-auctions',
                    label: 'Global Auction Houses',
                    url: '/auction-houses',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
            ],
            group: 'a',
        },
        {
            id: 'galleries',
            label: 'Galleries',
            url: '/galleries/',
            uiSpec: {
                useServerSideRouting: true,
            },
            group: 'a',
        },
        {
            id: 'events',
            label: 'Events',
            url: '/events/',
            uiSpec: {
                useServerSideRouting: true,
            },
            group: 'a',
        },
        {
            id: 'news',
            label: 'News',
            url: `https://${newsHost}/`,
            uiSpec: {
                useServerSideRouting: true,
            },
            group: 'a',
        },
        {
            id: 'price-database',
            label: 'Price Database',
            // label: 'Price Database & Analytics',

            // note: parent items are not links themselves; the url is just here to ensure that
            // this link is underlined when at artnet.com/price-database/
            url: '/price-database/',
            uiSpec: {
                mobile: 'pb-4',
            },
            links: [
                {
                    id: 'price-database',
                    label: 'Use the Artnet Price Database',
                    url: '/price-database',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'market-alerts',
                    label: 'Market Alerts',
                    url: '/market-alerts',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'analytics',
                    label: 'Artnet Analytics',
                    url: '/analytics-reports',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
            ],
            group: 'a',
        },
        {
            id: 'buy',
            label: 'Buy',
            uiSpec: {
                isExternal: false,
                mobile: 'border-t-[1px] border-neutral-200 pt-2 font-bold',
                // desktop:
                //     '-mr-10 font-bold text-brand-black before:w-[1px] before:left-[-1.5rem] before:h-3 before:absolute before:top-[0.38rem] before:bg-neutral-200',
                desktop: 'font-bold pr-4',
            },
            links: [
                {
                    id: 'browse-artists',
                    label: 'Browse Artists',
                    url: '/artists/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'artnet-auctions',
                    label: 'Artnet Auctions',
                    url: '/auctions/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'galleries',
                    label: 'Browse Galleries',
                    url: '/galleries/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'global-auctions',
                    label: 'Global Auction Houses',
                    url: '/auction-houses/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'events',
                    label: 'Events & Exhibitions',
                    url: '/events/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'expert',
                    label: 'Speak With a Specialist',
                    url: '/auctions/meet-the-specialists/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'financing',
                    label: 'Art Financing',
                    url: '#',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'how-to-buy',
                    label: 'How to Buy',
                    url: '/auctions/how-to-buy',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
            ],
            group: 'b',
        },
        {
            id: 'sell',
            label: 'Sell',
            uiSpec: {
                mobile: 'font-bold',
                // desktop: 'pr-2 font-bold',
                // desktop: 'font-bold pr-10',
                desktop:
                    'font-bold pr-10 before:absolute before:right-[1.4rem] before:top-[0.38rem] before:h-3 before:w-[1px] before:bg-neutral-200',
            },
            links: [
                {
                    id: 'sell-with-us',
                    label: 'Sell With Us',
                    url: '/auctions/sell/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'gallery-partner',
                    label: 'Become a Gallery Partner',
                    url: '/gallery-network/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'auction-partner',
                    label: 'Become an Auction Partner',
                    url: '/auction-house-partnerships/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'valuation',
                    label: 'Receive a Valuation',
                    url: '/auctions/sell/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
                {
                    id: 'how-to-sell',
                    label: 'How to Sell',
                    url: '/auctions/how-to-sell/',
                    uiSpec: {
                        useServerSideRouting: true,
                    },
                },
            ],
            group: 'b',
        },
        {
            id: 'search',
            label: 'Search',
            group: 'b',
        },
    ]

    const accountLinks: Record<string, NavLinkType> = isLoggedIn
        ? {
              'my-account': {
                  id: 'my-account',
                  label: 'My Account',
                  url: '/my-account',
                  uiSpec: {
                      useServerSideRouting: true,
                  },
              },
              auctions: {
                  id: 'auctions',
                  label: 'Auctions Account',
                  url: '/auctions/Pages/Customer/MyAccount.aspx',
                  uiSpec: {
                      useServerSideRouting: true,
                  },
              },
              'news-pro-subscription': {
                  id: 'news-pro-subscription',
                  label: 'News PRO Subscription',
                  url: `https://${newsHost}/my-account`,
                  uiSpec: {
                      useServerSideRouting: true,
                  },
              },
              'change-password': {
                  id: 'change-password',
                  label: 'Change Password',
                  url: authUrls?.changePassword,
                  uiSpec: {
                      useServerSideRouting: true,
                      mobile: 'border-y-[1px] border-neutral-200 pt-2',
                  },
              },
              logout: {
                  id: 'logout',
                  label: 'Log out',
                  url: authUrls?.logout,
                  uiSpec: {
                      useServerSideRouting: true,
                  },
              },
          }
        : {}

    return {
        logo: {
            url: '/',
            altText: 'Artnet Logo',
        },
        search: {
            placeholder: 'Search',
            name: 'search',
        },
        nav: {
            links: keyBy(navLinks, (link) => link.id),
        },
        logIn: {
            id: 'logIn',
            label: 'Log In or Register',
            url: authUrls?.login,
        },
        account: {
            isLoggedIn,
            displayName: userDisplayName,
            links: accountLinks,
        },
    }
}

function makeLinksAbsolute(
    links: HeaderData | Record<string, NavLinkType>,
    siteBaseUrl: string
) {
    const newLinks: Record<string, NavLinkType> = {}
    for (const [id, link] of Object.entries(links)) {
        const newLink = {
            ...link,
            links:
                link.links === undefined
                    ? undefined
                    : makeLinksAbsolute(link.links, siteBaseUrl),
        }

        // check if link is already absolute
        if (link.url && !link.url.startsWith('http')) {
            newLink.url = siteBaseUrl + link.url
        }

        newLinks[id] = newLink
    }
    return newLinks
}
