import React from 'react'
import { useFetcher } from 'react-router'
import { GlobalHeaderSearchView } from './views/GlobalHeaderSearchView'
import type { SearchHint, SearchType } from '~/services/graphql/generated'
import type { GlobalHeaderSearchContainerProps } from './types'

function useGlobalHeaderSearchData() {
    const fetcher = useFetcher<{
        results: SearchHint[]
    }>()

    const fetchHints = React.useCallback(
        (searchTerm: string, searchType: SearchType) => {
            if (!searchTerm) {
                return
            }

            fetcher.submit(
                {
                    query: searchTerm,
                    type: searchType,
                },
                { action: '/api/frontend/search' }
            )
        },
        [fetcher]
    )

    return {
        fetchHints,
        globalSearchResult: (fetcher.data?.results ?? []) as SearchHint[],
    }
}

export function GlobalHeaderSearchRemixContainer({
    siteBaseUrl,
    isSearchOpen,
    onToggleSearchOpen,
    desktopHeight,
    theme,
    getServerTemplateParam,
}: GlobalHeaderSearchContainerProps) {
    const { globalSearchResult, fetchHints } = useGlobalHeaderSearchData()

    return (
        <GlobalHeaderSearchView
            siteBaseUrl={siteBaseUrl}
            searchResult={globalSearchResult}
            fetchHints={fetchHints}
            isSearchOpen={isSearchOpen}
            onToggleSearchOpen={onToggleSearchOpen}
            desktopHeight={desktopHeight}
            theme={theme}
            getServerTemplateParam={getServerTemplateParam}
        />
    )
}
